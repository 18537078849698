import dayjs from 'dayjs';
import { CardStatus } from 'app/shared/model/enumerations/card-status.model';
import { CardType } from 'app/shared/model/enumerations/card-type.model';

export interface ICard {
  id?: number;
  number?: string | null;
  code?: number | null;
  dueDate?: dayjs.Dayjs | null;
  status?: keyof typeof CardStatus | null;
  dailyLimit?: number | null;
  weeklyLimit?: number | null;
  monthlyLimit?: number | null;
  availableCredit?: number | null;
  type?: keyof typeof CardType | null;
  createAt?: dayjs.Dayjs | null;
  pausedAt?: dayjs.Dayjs | null;
}

export const defaultValue: Readonly<ICard> = {};
