import wallet from 'app/entities/wallet/wallet.reducer';
import card from 'app/entities/card/card.reducer';
import transaction from 'app/entities/transaction/transaction.reducer';
import token from 'app/entities/token/token.reducer';
import blockchain from 'app/entities/blockchain/blockchain.reducer';
import parameter from 'app/entities/parameter/parameter.reducer';
import transactionEvent from 'app/entities/transaction-event/transaction-event.reducer';
import tokenBlockchain from 'app/entities/token-blockchain/token-blockchain.reducer';
import notification from 'app/entities/notification/notification.reducer';
import faq from 'app/entities/faq/faq.reducer';
import faqCategory from 'app/entities/faq-category/faq-category.reducer';
import termsAndConds from 'app/entities/terms-and-conds/terms-and-conds.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  wallet,
  card,
  transaction,
  token,
  blockchain,
  parameter,
  transactionEvent,
  tokenBlockchain,
  notification,
  faq,
  faqCategory,
  termsAndConds,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
