// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ==========================================================================
Main page styles
========================================================================== */
.hipster {
  display: inline-block;
  width: 75%;
  height: 140px;
  background: url("/content/images/logo_bg.png") no-repeat center top;
  background-size: contain;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/home/home.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,qBAAA;EACA,UAAA;EACA,aAAA;EACA,mEAAA;EACA,wBAAA;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
