import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { CardStatus } from 'app/shared/model/enumerations/card-status.model';
import { CardType } from 'app/shared/model/enumerations/card-type.model';
import { createEntity, getEntity, reset, updateEntity } from './card.reducer';

export const CardUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const cardEntity = useAppSelector(state => state.card.entity);
  const loading = useAppSelector(state => state.card.loading);
  const updating = useAppSelector(state => state.card.updating);
  const updateSuccess = useAppSelector(state => state.card.updateSuccess);
  const cardStatusValues = Object.keys(CardStatus);
  const cardTypeValues = Object.keys(CardType);

  const handleClose = () => {
    navigate(`/card${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.code !== undefined && typeof values.code !== 'number') {
      values.code = Number(values.code);
    }
    values.dueDate = convertDateTimeToServer(values.dueDate);
    if (values.dailyLimit !== undefined && typeof values.dailyLimit !== 'number') {
      values.dailyLimit = Number(values.dailyLimit);
    }
    if (values.weeklyLimit !== undefined && typeof values.weeklyLimit !== 'number') {
      values.weeklyLimit = Number(values.weeklyLimit);
    }
    if (values.monthlyLimit !== undefined && typeof values.monthlyLimit !== 'number') {
      values.monthlyLimit = Number(values.monthlyLimit);
    }
    if (values.availableCredit !== undefined && typeof values.availableCredit !== 'number') {
      values.availableCredit = Number(values.availableCredit);
    }
    values.createAt = convertDateTimeToServer(values.createAt);
    values.pausedAt = convertDateTimeToServer(values.pausedAt);

    const entity = {
      ...cardEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          dueDate: displayDefaultDateTime(),
          createAt: displayDefaultDateTime(),
          pausedAt: displayDefaultDateTime(),
        }
      : {
          status: 'ACTIVE',
          type: 'VIRTUAL',
          ...cardEntity,
          dueDate: convertDateTimeFromServer(cardEntity.dueDate),
          createAt: convertDateTimeFromServer(cardEntity.createAt),
          pausedAt: convertDateTimeFromServer(cardEntity.pausedAt),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cryptoweApp.card.home.createOrEditLabel" data-cy="CardCreateUpdateHeading">
            <Translate contentKey="cryptoweApp.card.home.createOrEditLabel">Create or edit a Card</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="card-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('cryptoweApp.card.number')} id="card-number" name="number" data-cy="number" type="text" />
              <ValidatedField label={translate('cryptoweApp.card.code')} id="card-code" name="code" data-cy="code" type="text" />
              <ValidatedField
                label={translate('cryptoweApp.card.dueDate')}
                id="card-dueDate"
                name="dueDate"
                data-cy="dueDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField label={translate('cryptoweApp.card.status')} id="card-status" name="status" data-cy="status" type="select">
                {cardStatusValues.map(cardStatus => (
                  <option value={cardStatus} key={cardStatus}>
                    {translate(`cryptoweApp.CardStatus.${cardStatus}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('cryptoweApp.card.dailyLimit')}
                id="card-dailyLimit"
                name="dailyLimit"
                data-cy="dailyLimit"
                type="text"
              />
              <ValidatedField
                label={translate('cryptoweApp.card.weeklyLimit')}
                id="card-weeklyLimit"
                name="weeklyLimit"
                data-cy="weeklyLimit"
                type="text"
              />
              <ValidatedField
                label={translate('cryptoweApp.card.monthlyLimit')}
                id="card-monthlyLimit"
                name="monthlyLimit"
                data-cy="monthlyLimit"
                type="text"
              />
              <ValidatedField
                label={translate('cryptoweApp.card.availableCredit')}
                id="card-availableCredit"
                name="availableCredit"
                data-cy="availableCredit"
                type="text"
              />
              <ValidatedField label={translate('cryptoweApp.card.type')} id="card-type" name="type" data-cy="type" type="select">
                {cardTypeValues.map(cardType => (
                  <option value={cardType} key={cardType}>
                    {translate(`cryptoweApp.CardType.${cardType}`)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('cryptoweApp.card.createAt')}
                id="card-createAt"
                name="createAt"
                data-cy="createAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('cryptoweApp.card.pausedAt')}
                id="card-pausedAt"
                name="pausedAt"
                data-cy="pausedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/card" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CardUpdate;
