import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Wallet from './wallet';
import Card from './card';
import Transaction from './transaction';
import Token from './token';
import Blockchain from './blockchain';
import Parameter from './parameter';
import TransactionEvent from './transaction-event';
import TokenBlockchain from './token-blockchain';
import Notification from './notification';
import Faq from './faq';
import FaqCategory from './faq-category';
import TermsAndConds from './terms-and-conds';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="wallet/*" element={<Wallet />} />
        <Route path="card/*" element={<Card />} />
        <Route path="transaction/*" element={<Transaction />} />
        <Route path="token/*" element={<Token />} />
        <Route path="blockchain/*" element={<Blockchain />} />
        <Route path="parameter/*" element={<Parameter />} />
        <Route path="transaction-event/*" element={<TransactionEvent />} />
        <Route path="token-blockchain/*" element={<TokenBlockchain />} />
        <Route path="notification/*" element={<Notification />} />
        <Route path="faq/*" element={<Faq />} />
        <Route path="faq-category/*" element={<FaqCategory />} />
        <Route path="terms-and-conds/*" element={<TermsAndConds />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
