import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './card.reducer';

export const CardDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const cardEntity = useAppSelector(state => state.card.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="cardDetailsHeading">
          <Translate contentKey="cryptoweApp.card.detail.title">Card</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{cardEntity.id}</dd>
          <dt>
            <span id="number">
              <Translate contentKey="cryptoweApp.card.number">Number</Translate>
            </span>
          </dt>
          <dd>{cardEntity.number}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="cryptoweApp.card.code">Code</Translate>
            </span>
          </dt>
          <dd>{cardEntity.code}</dd>
          <dt>
            <span id="dueDate">
              <Translate contentKey="cryptoweApp.card.dueDate">Due Date</Translate>
            </span>
          </dt>
          <dd>{cardEntity.dueDate ? <TextFormat value={cardEntity.dueDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="cryptoweApp.card.status">Status</Translate>
            </span>
          </dt>
          <dd>{cardEntity.status}</dd>
          <dt>
            <span id="dailyLimit">
              <Translate contentKey="cryptoweApp.card.dailyLimit">Daily Limit</Translate>
            </span>
          </dt>
          <dd>{cardEntity.dailyLimit}</dd>
          <dt>
            <span id="weeklyLimit">
              <Translate contentKey="cryptoweApp.card.weeklyLimit">Weekly Limit</Translate>
            </span>
          </dt>
          <dd>{cardEntity.weeklyLimit}</dd>
          <dt>
            <span id="monthlyLimit">
              <Translate contentKey="cryptoweApp.card.monthlyLimit">Monthly Limit</Translate>
            </span>
          </dt>
          <dd>{cardEntity.monthlyLimit}</dd>
          <dt>
            <span id="availableCredit">
              <Translate contentKey="cryptoweApp.card.availableCredit">Available Credit</Translate>
            </span>
          </dt>
          <dd>{cardEntity.availableCredit}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="cryptoweApp.card.type">Type</Translate>
            </span>
          </dt>
          <dd>{cardEntity.type}</dd>
          <dt>
            <span id="createAt">
              <Translate contentKey="cryptoweApp.card.createAt">Create At</Translate>
            </span>
          </dt>
          <dd>{cardEntity.createAt ? <TextFormat value={cardEntity.createAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="pausedAt">
              <Translate contentKey="cryptoweApp.card.pausedAt">Paused At</Translate>
            </span>
          </dt>
          <dd>{cardEntity.pausedAt ? <TextFormat value={cardEntity.pausedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/card" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/card/${cardEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CardDetail;
