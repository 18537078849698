import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TermsAndConds from './terms-and-conds';
import TermsAndCondsDetail from './terms-and-conds-detail';
import TermsAndCondsUpdate from './terms-and-conds-update';
import TermsAndCondsDeleteDialog from './terms-and-conds-delete-dialog';

const TermsAndCondsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TermsAndConds />} />
    <Route path="new" element={<TermsAndCondsUpdate />} />
    <Route path=":id">
      <Route index element={<TermsAndCondsDetail />} />
      <Route path="edit" element={<TermsAndCondsUpdate />} />
      <Route path="delete" element={<TermsAndCondsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TermsAndCondsRoutes;
